export default {
  data: function data() {
    return {
      list1: [{
        img: require("@/assets/warehousing/f/1.png"),
        t: "识别速度快",
        c: "标签一进入识别区域，阅读器就可以及时读取多个标签；极大提高扫描效率，节约识别时间"
      }, {
        img: require("@/assets/warehousing/6.png"),
        t: "自动出入库",
        c: "标签经过识别区域，自动读取标签信息，经过运动方向的判断，完成自动出入库信息采集"
      }, {
        img: require("@/assets/warehousing/8.png"),
        t: "穿透性阅读",
        c: "RFID能穿透纸张、木材和塑料等非金属和非液体的材质，即使多箱物品堆叠，也可识别标签"
      } // {
      //   img: require("@/assets/warehousing/9.png"),
      //   t: "数据容量大",
      //   c: "RFID最大的容量有MegaBytes。随着记忆载体的发展，数据容量也有不断扩大的趋势。",
      // },
      ],
      list2: [{
        img: require("@/assets/warehousing/10.png"),
        t: "适应恶劣环境",
        c: "RFID的无线电通信方式，可以应用于粉尘、黑暗等环境。不需要光源，也可识别标签"
      }, {
        img: require("@/assets/warehousing/11.png"),
        t: "标签数据可更改",
        c: "RFID标签内容可更改，以便重复使用RFID标签，摆脱传统标签只能使用代表一种信息、只能使用一次的情况"
      }, {
        img: require("@/assets/warehousing/12.png"),
        t: "形状多样化",
        c: "根据仓库里存储的不同物品和使用情况，匹配不同尺寸、材质的标签，满足多个场景的应用需求"
      } // {
      //   img: require("@/assets/warehousing/13.png"),
      //   t: "体积小型化、形状多样化",
      //   c: "品质，更适合小型化与多样形态附着不同类型的产品。",
      // },
      ],
      list: [{
        img: require("@/assets/warehousing/6.png"),
        t: "自动出入库",
        c: "使用有源电子标签，有效识别距离可达到30米以上。有效解决了以往条形码仓储必须手动扫描条形码的问题，实现自动出入库，极大提高仓储人工成本和叉车使用效率。"
      }, {
        img: require("@/assets/warehousing/7.png"),
        t: "识别速度快",
        c: "标签一进入磁场，阅读器就可以即时读取其中的信息；利用RFID防碰撞技术和固定式读写器，可以瞬间读取数十上百个标签，极大提高扫描效率，降低人工成本。"
      }, {
        img: require("@/assets/warehousing/8.png"),
        t: "穿透性和无屏碍阅读",
        c: "RFID能穿透纸张、木材和塑料等非金属和非透明的材质，进行穿透性通信，不需要光源。提供更佳扫描体验，可快速理货、找货、盘点，是仓库管理加速器。"
      }, {
        img: require("@/assets/warehousing/9.png"),
        t: "数据容量大",
        c: "RFID最大的容量有MegaBytes。随着记忆载体的发展，数据容量也有不断扩大的趋势。物品所需携带的资料量会越来越大，对标签所能扩充容量的需求也相应增加。"
      }, {
        img: require("@/assets/warehousing/10.png"),
        t: "使用寿命长，适应恶劣环境",
        c: "RFID的无线电通信方式，使其可以应用于粉尘、油污等高污染环境和放射性环境，拥有大于10万次读写寿命；且卷标是将数据存在芯片中，使其抗污染能力和永久性更强。"
      }, {
        img: require("@/assets/warehousing/11.png"),
        t: "标签数据可动态更改",
        c: "RFID标签内容可更改，可重复使用RFID标签，摆脱传统条形码标签只能使用一次的情况，可有效降低企业耗材费用（使用条形码仓储系统的企业每年都要采购大量的标签和碳带）。"
      }, {
        img: require("@/assets/warehousing/12.png"),
        t: "更好的安全性",
        c: "将产品数据从中央计算机中转存到标签内，为系统提供安全保障，并运用加密方法对RFID标签内的数据进行保护，可以保证数据不被非法读取。"
      }, {
        img: require("@/assets/warehousing/13.png"),
        t: "体积小型化、形状多样化",
        c: "RFID不需要为读取精确度而配合纸张的固定尺寸和印刷品质，更适合往小型化与多样形态发展，以方便嵌入或附着在不同形状、类型的产品上。"
      }]
    };
  }
};