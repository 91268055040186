import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top"; // import warehousingA from "./components/warehousing-a";
// import warehousingB from "./components/warehousing-b";
// import warehousingC from "./components/warehousing-c";

import warehousingD from "./components/warehousing-d";
import warehousingE from "./components/warehousing-e";
import warehousingF from "./components/warehousing-f"; // import warehousingG from "./components/warehousing-g";

import CommonFooter1 from "@/components/common-footer1";
import { mapMutations } from "vuex";
import CommonList2 from "@/components/common-list2";
export default {
  metaInfo: {
    title: "智能仓储管理方案",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "RFID仓储物流管理系统,RFID仓库管理系统,RFID仓储管理系统,RFID智能仓储管理系统,常达"
    }, {
      name: "description",
      content: "自动管理仓库系统(WMS)已经大规模地应用于各个行业，特别是在制造业及物流业。一套好的WMS 除了可以整合各种设备系统之外，也可以提供数据连接到整个企业的讯息化系统里，为企业提供更多样的讯息。"
    }]
  },
  data: function data() {
    return {
      commonColumnSwiperOption: {
        defaultTop: 20,
        leftT: ["方案价值"],
        leftC: " ",
        list: [{
          t: "降低人工",
          c: "人工可降低20-30%",
          img: require("@/assets/medical-treatment/b/1.png")
        }, {
          t: "产品可视化",
          c: "人工可降低20-30%的仓库产品可视化，降低商品缺失的风险",
          img: require("@/assets/medical-treatment/b/2.png")
        }, {
          t: "降低时间",
          c: "改良的供应链管理降低20-25%的工作服务时间",
          img: require("@/assets/medical-treatment/b/3.png")
        }, {
          t: "提高准确性",
          c: "库存的可用性提高5% ~10%，提高仓储信息的准确性与可靠性",
          img: require("@/assets/medical-treatment/b/4.png")
        }, {
          t: "提高效率",
          c: "提高仓库产品的吞吐量，高效、准确的数据采集、提供作业效率",
          img: require("@/assets/medical-treatment/b/5.png")
        }, {
          t: "降低失误",
          c: "入库、出库数据自动采集，降低人为失误",
          img: require("@/assets/medical-treatment/b/4.png")
        }, {
          t: "降低成本",
          c: "场地管理费减少30% ，减少损坏率和过期商品的销账可达20%，降低企业仓储物流成本",
          img: require("@/assets/medical-treatment/b/5.png")
        }]
      },
      list: [{
        image: require("@/assets/warehousing/swiper/1.jpg"),
        text: "产品贴标",
        imgOffset: "-30%",
        icon: require("@/assets/warehousing/swiper/icon1.png"),
        icon1: require("@/assets/warehousing/swiper/icon1c.png")
      }, {
        image: require("@/assets/warehousing/swiper/2.jpg"),
        text: "产品入库",
        imgOffset: "-41%",
        icon: require("@/assets/warehousing/swiper/icon2.png"),
        icon1: require("@/assets/warehousing/swiper/icon2c.png")
      }, {
        image: require("@/assets/warehousing/swiper/3.jpg"),
        text: "产品上架",
        imgOffset: "-10%",
        icon: require("@/assets/warehousing/swiper/icon3.png"),
        icon1: require("@/assets/warehousing/swiper/icon3c.png")
      }, {
        image: require("@/assets/warehousing/swiper/4.jpg"),
        text: "仓位移动",
        imgOffset: "-19%",
        icon: require("@/assets/warehousing/swiper/icon4.png"),
        icon1: require("@/assets/warehousing/swiper/icon4c.png")
      }, {
        image: require("@/assets/warehousing/swiper/5.jpg"),
        text: "产品配货",
        imgOffset: "-45%",
        icon: require("@/assets/warehousing/swiper/icon5.png"),
        icon1: require("@/assets/warehousing/swiper/icon5c.png")
      }, {
        image: require("@/assets/warehousing/swiper/6.jpg"),
        text: "产品出库",
        imgOffset: "-10%",
        icon: require("@/assets/warehousing/swiper/icon6.png"),
        icon1: require("@/assets/warehousing/swiper/icon6c.png")
      }],
      recommend: {
        title: "仓储管理产品推荐",
        list: [{
          image: require("@/assets/warehousing/recommend/1.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=3"
        }, {
          image: require("@/assets/warehousing/recommend/2.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=0"
        }, {
          image: require("@/assets/warehousing/recommend/3.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/warehousing/recommend/4.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=1"
        }, {
          image: require("@/assets/warehousing/recommend/5.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=2"
        }]
      },
      list2: [{
        icon: require("@/assets/warehousing/list/icon1.png"),
        t: "降低失误",
        c: "人工可降低20-30%入库、出库数据自动采集，降低人为失误。"
      }, {
        icon: require("@/assets/warehousing/list/icon2.png"),
        t: "产品可视化",
        c: "人工可降低20-30%的仓库产品可视化，降低商品缺失的风险。"
      }, {
        icon: require("@/assets/warehousing/list/icon3.png"),
        t: "降低时间",
        c: "改良的供应链管理降低20-25%的工作服务时间。"
      }, {
        icon: require("@/assets/warehousing/list/icon4.png"),
        t: "提高准确性",
        c: "库存的可用性提高5% ~10%，提高仓储信息的准确性与可靠性。"
      }, {
        icon: require("@/assets/warehousing/list/icon5.png"),
        t: "提高效率",
        c: "提高仓库产品的吞吐量，高效、准确的数据采集、提供作业效率。"
      }, {
        icon: require("@/assets/warehousing/list/icon6.png"),
        t: "降低成本",
        c: "场地管理费减少30% ，减少损坏率和过期商品的销账可达20%，降低企业仓储物流成本。"
      }]
    };
  },
  components: {
    CommonList2: CommonList2,
    CommonFooter1: CommonFooter1,
    top: top,
    // warehousingA,
    // warehousingB,
    // warehousingC,
    warehousingD: warehousingD,
    warehousingE: warehousingE,
    warehousingF: warehousingF // warehousingG,

  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};