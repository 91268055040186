export default {
  data: function data() {
    return {
      current: 0,
      nav: [{
        title: "登录系统",
        list: [{
          t: "登录方式",
          image: require("@/assets/warehousing/e/login/1.png")
        }, {
          t: "首页功能展示",
          image: require("@/assets/warehousing/e/login/2.png")
        }]
      }, {
        title: "基础信息",
        list: [{
          t: "仓储管理",
          image: require("@/assets/warehousing/e/basis/1.png")
        }, {
          t: "仓库储位置",
          image: require("@/assets/warehousing/e/basis/2.png")
        }, {
          t: "配送车辆",
          image: require("@/assets/warehousing/e/basis/3.png")
        }]
      }, {
        title: "产品信息",
        list: [{
          t: "产品管理",
          image: require("@/assets/warehousing/e/product/1.png")
        }, {
          t: "库存列表",
          image: require("@/assets/warehousing/e/product/2.png")
        }, {
          t: "出库警报记录",
          image: require("@/assets/warehousing/e/product/3.png")
        }]
      }, {
        title: "装车配送",
        list: [{
          t: "门店进货单",
          image: require("@/assets/warehousing/e/delivery/1.png")
        }, {
          t: "装车单",
          image: require("@/assets/warehousing/e/delivery/2.png")
        }]
      }, {
        title: "系统设置",
        list: [{
          t: "权限管理",
          image: require("@/assets/warehousing/e/set/1.png")
        }, {
          t: "账户管理",
          image: require("@/assets/warehousing/e/set/2.png")
        }]
      }]
    };
  },
  computed: {
    list: function list() {
      var current = this.current,
          nav = this.nav;
      return nav[current].list || [];
    }
  }
};